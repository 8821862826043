import React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"

// Lazy load the ProductStrategy component
const ProductStrategy = loadable(() => import("../components/ProductStrategy"))

export default function ServicesPage() {
  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://codefulcrum.com/product-strategy/"
        />
      </Helmet>
      {/* No need for React.Suspense since loadable handles loading states */}
      <ProductStrategy />
    </Layout>
  )
}
